import React, { useEffect } from 'react';
import img from "../../assets/images/ball-mainbanner.png";
import translateString from "../../utils/Locales";
import video from "../../images/CRM.mp4";
import videoMobile from "../../images/CRM_mobile.mp4";

let t = translateString;

const VideoBanner = (props) => {

    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1080) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleContactClick = () => {
		console.log('contactRef'); 
        props.contactRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="main-banner mainbanner position-relative">
            <div className="d-table">
                <div className="d-table-cell" style={{ backgroundSize: 'cover' }}> 
                    <video 
                        style={{ objectFit: 'cover', position: 'absolute', top: 0, left: 0, width: "100vw", height: "100vh", zIndex: -2 }} 
                        src={isMobile ? videoMobile : video} 
                        autoPlay 
                        muted 
                        playsInline 
                    />  
                </div>
            </div> 
            {/* <button 
                onClick={handleContactClick}
                className="btn-slogan" 
                style={{ 
                    left: isMobile ? '50%' : '21%',   
                    width: isMobile ? '80%' : 'auto', 
                    transform: 'translateX(-50%)',
                    position: 'absolute', 
                    bottom: '7%'
                }}
            >
                Scopri di più
            </button> */}
        </div>
    )
}

export default VideoBanner;
