import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import MainBanner from "../components/ITStartup/MainBanner";
import Features from "../components/ITStartup/Features";
import ServicesArea from "../components/ITStartup/ServicesArea";
import RecentWorks from "../components/Common/RecentWorks";
import Footer from "../components/_App/Footer";
import { logPage } from '../analytics/analytics';
import { MessengerChat } from 'react-messenger-chat-plugin';
import '../assets/css/bootstrap.min.css';
import GoogleManager from "../utils/GoogleManager";
import AboutUs from "../components/ITStartup/AboutUs";
import Description from "../components/Crm/description";
import ContactForm from "../components/Crm/ContactForm"; 
import VideoBanner from "../components/Crm/VideoBanner";

const IndexPage = () => { 
    const contactRef = React.useRef(null); 

    logPage('CRM');

    return (
    <Layout>
        <GoogleManager />
        <SEO title="CRM & ERP - Software House & Web Agency" />
        <Navbar/>
        <VideoBanner contactRef={contactRef} />
        <Description contactRef={contactRef} />  
        <ContactForm contactRef={contactRef} />
        <Footer /> 
    </Layout>
    )
    }


export default IndexPage;
