import React from 'react'; 
import translateString from "../../utils/Locales";
import ombra from '../../images/ombra-telefoni.png';
import { useEffect } from 'react';
import Testimonianze from "../Contact/Testimonianze";
import { Link } from "gatsby";
import AboutOne from "../../images/info.png";
import bgInvert from '../../images/api-verdi.png';



let t = translateString;

const Description = (props) => {

	const [isMobile, setMobile] = React.useState(false);
	const [width, setWidth] = React.useState(0);

    const handleResize = () => {
		setWidth(window.innerWidth);
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        } 
    }

	const handleContactClick = () => {
		console.log('contactRef'); 
        props.contactRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
		<> 
			<div className={`about-area`}>
				<div className="bg-crm" style={{ paddingBottom: isMobile ? 0 : 100}}>
					<div className="container">
						<div className="row justify-content-between" style={{margin: isMobile ? "0 auto" : ''}}>
							<div className="col-lg-7 col-md-12" >
								<div className="about-content">
									<div style={{textAlign: 'start', maxWidth: '100%'}}> 
										<p className="BrinnanLight" style={{ fontSize:17 }}>Sappiamo bene quanto sia importante per una piccola realtà imprenditoriale avere strumenti che non solo rispondano alle esigenze quotidiane ma che possano anche crescere con l’azienda.
										Ecco perché Appius offre personalizzazioni di Odoo, una piattaforma ERP (Enterprise Resource Planning) e CRM (Customer Relationship Management) che può trasformare la gestione della vostra azienda.</p>
										<p className="BrinnanLight" style={{ marginTop: 30, fontSize:17 }}>Siamo qui per accompagnarti in ogni fase del ciclo di vita del software, dall’ideazione alla implementazione, fino al supporto continuo. Il nostro team, che lavora felicemente da remoto, porta valore aggiunto grazie alla flessibilità e alla dedizione che caratterizzano il nostro lavoro.</p>
										<p className="BrinnanLight" style={{ marginTop: 30, fontSize:17 }}>Contattaci per scoprire come possiamo trasformare la tua azienda con personalizzazioni Odoo su misura.</p>
									</div>
								</div>
								<br/><br/>
								<div className={`position-relative mt-4${isMobile?" d-flex justify-content-center mb-5" : " text-end"}`}>
									<button style={{marginRight: 30}} onClick={handleContactClick} className="btn-slogan">
										Contattaci
									</button>
								</div> 
							</div> 
						</div>
					</div>
				</div> 
			</div>
			<Testimonianze />
		</>
    )
}

export default Description;
